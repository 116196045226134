import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby' 
import { Container, Row, Col, Button } from 'reactstrap'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import Link from '../../../components/Link'
import ModalVideo from '../../../components/ModalVideo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import LocaleContext from '../../../components/LocaleContext'
import {bookADemoLinkSwitch} from '../../../components/lib/bookADemoLinkSwitch'

import './eat-my-lunch.scss'

import staffImage from '../../../images/case-study/Brett_Pearson.jpg'
import sylvieImage from '../../../images/case-study/Sylvie_Case_Study.png'
import kidsImage from '../../../images/case-study/eat-my-lunch-kids-2.jpg'
import bannerImg from '../../../images/case-study/Sales_Tile_2022-04.png'
import emlImg from '../../../images/case-study/EML.png'
import icon1 from '../../../images/case-study/icon1.png'
import icon2 from '../../../images/case-study/icon2.png'
import payHeroImage from '../../../images/case-study/PayHero_Full.png'
import xeroImage from '../../../images/case-study/xero-logo-1.png'

import imageVid from '../../../images/case-study/Screen_Shot_2022-06-01_at_2.07.02_PM.png'
const video = {
  url: 'https://www.youtube.com/embed/JEvPBipocqg',
  channel: 'youtube',
}



export default () => {
  const locale = useContext(LocaleContext)
  const demoLink = bookADemoLinkSwitch(locale.value)

  return (
    <Layout
      className="CaseStudyPage"
      title="Customer Story"
    >
      <Hero>
        <span className="hashTag"><span>#</span>customerstories</span>
        <h1>How 'Eat My Lunch' used MyHR to create a smooth HR operation.</h1>
        <ModalVideo {...video}>
          {({ toggle }) => 
          <Link
            className={`px-3 px-md-4`}
            onClick={toggle}
            type="button"
            color="cyan"
          >
            <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
            Watch Video
          </Link>
          }
        </ModalVideo>
      </Hero>
      <Section className="intro">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
                <h2 className="mb-5">Company Profile</h2>
                <p className="mb-6 introText">Eat my Lunch is a quintessentially Kiwi business, helping hungry kids across the country access 
                   healthy food. Their buy one, give one business model is simple; for every lunch purchased means 
                   Eat my Lunch gives a lunch to a Kiwi kid who would otherwise go without one. We spoke with Brett 
                   Pearson, National Operations Director, about how MyHR has helped the business since they adopted 
                   the platform in 2018.
                </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <div className="threeBlockItem">
                <img src={emlImg} className="" alt="Eat My Lunch Logo" />
            </div>
            <div className="threeBlockItem">
                <img src={icon1} className="icon" alt="Icon" />
                150<br />Employees
            </div>
            <div className="threeBlockItem">
                <img src={icon2} className="icon2" alt="Icon" />
                Auckland and Wellington
            </div>
          </Row>
        </Container>
      </Section>

      <Section className="">
        <Container>
          <Row className="flex-column-reverse flex-lg-row">
            <Col
              md={12}
              lg={7}
              className="d-none d-lg-block"
            >
              <span className="staffName">BRETT PEARSON, NATIONAL OPERATIONS DIRECTOR</span>
              <img src={staffImage} className="leftImg3c" alt="Brett Pearson" />
            </Col>
            <Col
              md={12}
              lg={5}
              className="mb-3 mb-md-0 d-flex align-items-start flex-column justify-content-center"
            >
              <h2 className="mb-4">Challenge</h2>
              <p>
                The Eat My Lunch school programme currently provides meals to over 14,500 children everyday, 
                which presents logistical challenges for any company.
              </p>
              <p className="blockQuote">
                "We have a lot of people come and go through the business which means we are continually recruiting".
              </p>
              <p>
                There are 150 employees spread across three sites in Auckland and Wellington so being able to onboard 
                new employees quickly and efficiently is critical to the success of the Eat my Lunch programme.
              </p>
              <p>
                There was a clear need for centralised people data that is easy to access, understand, and manage. 
                Without dedicated internal HR, keeping on top of people management and staying compliant required a 
                lot of time and admin.
              </p>
              <p>
                According to Brett, MyHR's smart software combined with expert support has assisted him in creating 
                a smooth onboarding process where employees can manage their data – leave, contracts, payslips - 
                from a single, easy-to-use source.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="bg-gray-triangle-new-4-opp content greyBack">
        <Container>
          <Row>
            <Col
              md={12}
              lg={5}
              className="mb-3 mb-md-0 d-flex align-items-start flex-column justify-content-center"
            >
              <h2 className="mb-3">Solution</h2>

              <h3>Paperless Onboarding</h3>
              <p>
                Brett now spends less time worrying about HR and more time making sure the day-to-day function of 
                the business runs smoothly.
              </p>
              <p>
                "With more than 150 employees in Auckland and Wellington, with many people coming and going, we can 
                hire new employees digital- ly and seamlessly. Electronic contracts save time and effort and mean 
                we can spread the workload by self-training other managers."
              </p>
              <p><strong>Key onboarding features:</strong></p>
              <ul>
                <li>Recruitment strategy and workforce planning</li>
                <li>Tailored employment agreements</li>
                <li>Complete employee self-service portal</li>
                <li>Onboarding & inductions plans for new hires</li>
              </ul>
              
              <h3>Data management</h3>
              <p>
                With so many people coming and going, most of whom are on casual contracts, the admin involved 
                reviewing and creating cus- tomised documents can be time-consuming. The MyHR platform allows managers 
                to view and manage team performance and data from a central location.
              </p>
              <p><strong>Key data management features:</strong></p>
              <ul>
                <li>Complete employee overview from pay rates, leave and more</li>
                <li>Employee record and file management</li>
                <li>Custom reminders for key employee events such as work visas or trial periods and upcoming performance reviews</li>
              </ul>

              <h3>Expert HR support team</h3>
              <p>
                An all-in-one software and services platform provide an additional layer of HR expertise and on-demand 
                help if you need it.
              </p>
              <p>
                "You're not talking to a machine you're talking to a human," says Brett.
              </p>
              <p>
                MyHR consultants provide ongoing training and HR support, "the ease of communication between the customer 
                and the MyHR sup- port team gives you confidence in the team management."
              </p>
              <p><strong>MyHR advisors can help with:</strong></p>
              <ul>
                <li>Fully customised employment documents and company policies that MyHR review and update every time</li>
                <li>Complete documentation audit</li>
                <li>Full legal compliance & oversight</li>
                <li>Expert oversight</li>
                <li>Modern Award Coverage</li>
                <li>+ more!</li>
              </ul>

              <h3>Software integration</h3>
              <p>
                MyHR's software integrations have helped companies manage all employment-related matters from a single source. 
                "The beauty of the Payhero and Xero integrations is that we can pay employees on time, create rosters and 
                timesheets, and manage those teams in real-time."
              </p>
              <div className="d-flex align-items-center flex-direction-row mt-4">
                <img src={payHeroImage} className="payHeroLogo" alt="Pay Hero" />
                <img src={xeroImage} className="xeroLogo" alt="Xero" />
              </div>

            </Col>
            <Col md={12} lg={7}>
              <p className="bigQuote">
                  "With more than 150 employees across the country, with many people coming and going, we can now hire new employees digitally and seamlessly."
                  <span>BRETT PEARSON</span>
              </p>
              <img src={sylvieImage} className="rightImg" alt="Sylvie" />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="bg-gray-triangle-new-4-opp2 mb-6">
        <Container>
          <Row>
            <Col
              md={12}
              lg={6}
              className="pr-5 d-flex align-items-start flex-column justify-content-center"
            >
              <img src={kidsImage} className="" alt="Kids" />
            </Col>
            <Col md={12} lg={6} className="pl-5 d-flex align-items-start flex-column justify-content-center">
              <h2>The impact</h2>
              <p>
                As MyHR takes the stress out of compliance, documentation, and people management, 
                Brett can focus on what matters: feeding hungry tummies across New Zealand.
              </p>
              <p>
                The platform is visually appealing and easy to find, Brett says. Not only has it 
                reduced confusion within the team, but the simple soft- ware means everyone can 
                manage their leave and onboard new employees with ease.
              </p>
              <p>
                "This has led to a very engaged team and efficient processes."
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="BannerSection py-5">
        <Container>
          <Row className="my-5">
            <Col
              md={{ size: 12, offset: 0 }}
              lg={{ size: 6, offset: 0 }}
            >
              <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                <div>
                  <div className="d-block d-md-none my-3">
                    <ModalVideo {...video}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={imageVid} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </div>
                  <p className="my-4 vid-quote-text">
                    "The ease of communication between the customer and the MyHR support team gives you 
                    confidence in the team management."
                  </p>
                  <div className="customerStoryBy">
                    <p>
                      <strong>Brett Pearson</strong>
                      <br />
                      <span>National Operations Manager</span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="d-none d-md-block"
              md={{ size: 12 }}
              lg={{ size: 6 }}
            >
              <ModalVideo {...video}>
                {({ toggle }) => (
                  <div className="UntangleHrSection__video" onClick={toggle}>
                    <img src={imageVid} alt={'Video Thumbnail'} />
                    <div className="UntangleHrSection__video-play-icon">
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="icon play-icon"
                      />
                    </div>
                  </div>
                )}
              </ModalVideo>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="BannerSectionFooter py-md-2 position-relative">
        <Container>
          <Row className="flex-column-reverse flex-lg-row">
            <Col md={{ size: 5 }} lg={{ size: 5 }} className="text-center">
              <img src={bannerImg} className="bannerImg" alt="Get Started with MyHR" />
            </Col>
            <Col md={{ size: 6 }} lg={{ size: 5, offset: 1 }} className="">
              <div className="h-100 d-flex align-items-center align-items-md-start flex-column justify-content-center text-center text-md-left">
                <div>
                  <h1 className="h2 mb-2">Get Started with MyHR</h1>
                  <p className="my-4">
                    We could go on all day, but... experiencing is believing. Book a demo today and start making HR easy.
                  </p>
                  <Button
                    color="electric-blue"
                    href={demoLink}
                    className="mr-3"
                  >
                    Book A Demo
                  </Button>
                  <Link
                    href={"/trial"}
                    localePrefix={true}
                    type="button"
                    color="cyan"
                    className={`btn-free-trial`}
                  >
                    Free Trial
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

    </Layout>
  );
}

/*
export const pageQuery = graphql`
  query CustomerStoryPageByUrl($slug: String!) {
    contentfulCustomerStory(slug: { eq: $slug } ) {
      ...ContentfulCustomerStoryFragment
    }
    relatedContent: allContentfulCustomerStory(filter: {slug: {ne: $slug}}, limit: 3, sort: {order: DESC, fields: date}) {
      edges {
        node {
          __typename
          ...ContentfulCustomerStoryFragment
        }
      }
    }
  }
`*/